<template>
  <van-nav-bar
    :title="title"
    right-text="创建"
    @click="onCreate"
  />
  <form action="/">
    <van-search v-model="searchKey" placeholder="搜索" @clear="onClear" @search="onSearch" />
  </form>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item.staffId">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 7px 0;">
          <small>员工id： {{ item.staffId }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>手机号： {{ item.user.phone }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>姓名： {{ item.name }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>注册： {{ item.createTime }}</small>
        </p>
        <van-button style="margin-right:7px" size="small" type="primary" :to="{ name: 'StaffEdit', query: { staffId: item.staffId } }">修改资料</van-button>
        <van-button style="margin-right:7px" size="small" type="primary" @click="openBox(1, item.staffId)">绑定会员</van-button>
        <van-button size="small" type="primary" @click="openBox(2, item.staffId)">会员列表</van-button>
      </div>
    </template>
  </van-list>
  <van-action-sheet v-model:show="memberShow" :title="memberType === 1 ? '绑定会员' : '会员列表'" :close-on-click-overlay="false" :lock-scroll="false">
    <div>
      <form action="/">
        <van-search v-model="searchMemberKey" placeholder="搜索会员电话" @clear="onMemberClear" @search="onMemberSearch" />
      </form>
      <div class="listbox">
        <van-list
          style="height: 50vh;"
          v-model:loading="memberLoading"
          :finished="memberFinished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="getMemberList"
        >
          <template v-for="(item,index) in memberList" :key="index">
            <div style="background:#fff;padding:15px;margin:15px 0 0 0;display: flex;align-items: center;justify-content: space-between;" @click="pickMmber(item.memberId)">
              <div style="margin-right: 12px;width: 24px;">
                <van-icon v-if="memberIds.indexOf(item.memberId) !== -1" name="checked" size="24px" color="#1989fa"/>
                <div v-else class="boderbox"></div>
              </div>
              <div style="flex: 1;">
                <p style="margin:0 0 7px 0;">
                  <small>会员姓名： {{ item.name }}</small>
                </p>
                <p>
                  <small>会员电话： {{ item.user.phone }}</small>
                </p>
              </div>
              <div>
                <van-button block v-if="memberType === 2" size="mini" type="primary" @click.stop="getMemberDetail(item.memberId, index)">查看详情</van-button>
                <van-button block style="margin-left: 0;margin-top: 6px;" v-if="memberType === 2" size="mini" type="primary" @click.stop="openRecord(item.memberId, index)">消费记录</van-button>
              </div>
            </div>
          </template>
        </van-list>
      </div>
      <van-button :type="memberType === 1 ? 'primary' : 'warning'" block @click="bindMember" v-if="memberList.length > 0">{{ memberType === 1 ? '绑定' : '解除' }}选中会员</van-button>
    </div>
    <van-overlay :show="detailShow" :z-index="2002" :lock-scroll="false">
      <div class="formbox">
        <van-form @submit="onSubmit">
          <van-cell-group title="基本信息" inset>
            <van-field
              disabled
              v-model="memberPhone"
              name="phone"
              label="会员电话"
              placeholder="请输入"
            />
            <van-field
              v-model="memberForm.name"
              name="name"
              label="会员姓名"
              placeholder="请输入"
            />
            <van-field name="radio" label="会员性别">
              <template #input>
                <van-radio-group v-model="memberForm.gender" direction="horizontal">
                  <van-radio name="SECRET">秘密</van-radio>
                  <van-radio name="MALE">帅哥</van-radio>
                  <van-radio name="FEMALE">美女</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-model="memberForm.birthday"
              readonly
              label="会员生日"
              name="datetimePicker"
              placeholder="点击选择"
              @click="showBirth = true"
            />
            <van-popup v-model:show="showBirth" position="bottom">
              <van-datetime-picker
                type="date"
                @confirm="onBirthConfirm"
                @cancel="showBirth = false"
                :min-date="new Date('1950/01/01')"
                :max-date="new Date()"
              />
            </van-popup>
            <van-field
              readonly
              clickable
              name="areaCode"
              :model-value="memberForm.region"
              label="所在区域"
              placeholder="点击选择"
              @click="showArea = true"
            />
            <van-popup v-model:show="showArea" position="bottom">
              <van-area
                :columns-num="3"
                :area-list="areaList"
                @confirm="onConfirm"
                @cancel="showArea = false"
              />
            </van-popup>
            <van-field
              v-model="memberForm.address"
              name="address"
              label="详细地址"
              placeholder="请输入"
            />

          </van-cell-group>
          <van-cell-group title="会员标签" inset>
            <van-field name="tagbtn" label="">
              <template #input>
                 <van-button size="small" type="primary" @click="openTag">添加标签</van-button>
              </template>
            </van-field>
            <van-dialog v-model:show="showTag" title="添加标签" show-cancel-button @confirm="tagConfirm">
              <van-field
                v-model="tagText"
                name="tagText"
                label="标签名称"
                placeholder="请输入标签,最多10个字"
                :maxlength="10"
              />
            </van-dialog>
            <van-field name="tag" label="">
              <template #input>
                <div>
                  <div v-if="memberForm.label.length === 0" style="color: #999999;font-size: 12px;">尚未添加标签</div>
                  <van-tag size="large" style="margin-right: 6px;margin-bottom: 6px;" closeable @close="delTag(index)" type="primary" v-for="(item,index) in memberForm.label" :key="index">{{ item }}</van-tag>
                </div>
              </template>
            </van-field>
          </van-cell-group>
          <van-cell-group title="会员备注" inset style="border: 1px solid #f3f3f3;">
            <van-field
              v-model="memberForm.remarks"
              rows="1"
              autosize
              type="textarea"
              placeholder="请输入"
            />
          </van-cell-group>
          <div style="margin: 16px;">
            <van-button block style="margin-bottom: 8px;" type="primary" @click="detailShow = false">取消</van-button>
            <van-button block type="primary" native-type="submit" :loading="btnLoading">确认修改</van-button>
          </div>
        </van-form>
      </div>
    </van-overlay>
    <van-action-sheet v-model:show="orderShow" title="消费记录" :close-on-click-overlay="false">
      <van-tabs v-model:active="tab" @change="changeTab">
        <van-tab title="普通商品" name="GENERAL"></van-tab>
        <van-tab title="自由付" name="FREE_PAY"></van-tab>
      </van-tabs>
      <van-list
        style="height: 60vh;padding: 8px;"
        v-model:loading="orderLoading"
        :finished="orderFinished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="getOrderList"
      >
        <template v-for="(item,index) in orderList" :key="index">
          <div class="infobox">
            <p>
              <small>订单编号： {{ item.orderNumber }}</small>
            </p>
            <p>
              <small>下单时间： {{ item.createTime }}</small>
            </p>
            <van-card
              :num="item.productQuantity"
              :price="item.productPrice"
              :desc="item.productSkuText"
              :title="item.productName"
              :thumb="item.productImage"
            >
              <template #tags v-if="item.bookingDate">
                <van-tag plain type="danger">{{ item.bookingDate }}</van-tag>
              </template>
            </van-card>
          </div>
        </template>
      </van-list>
    </van-action-sheet>
  </van-action-sheet>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import Areas from '@/util/area'
export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      form: {
        show: false,
        row: {}
      },
      row: {},
      searchKey: '',
      storeId: Cookies.get('storeId'),
      memberShow: false,
      memberType: 1,
      memberList: [],
      memberLoading: false,
      memberPage: 1,
      memberFinished: false,
      memberIds: [],
      staffId: '',
      detailShow: false,
      memberForm: {
        memberId: '',
        name: '',
        gender: '',
        birthday: '',
        remarks: '',
        address: '',
        region: '',
        label: []
      },
      showArea: false,
      areaList: Areas,
      memberPhone: '',
      showBirth: false,
      showTag: false,
      tagText: '',
      btnLoading: false,
      listIndex: -1,
      orderShow: false,
      orderLoading: false,
      orderFinished: false,
      orderList: [],
      orderPage: 1,
      memberId: '',
      tab: 'GENERAL',
      searchMemberKey: ''
    })
    const changeTab = (e) => {
      state.orderList = []
      state.orderPage = 1
      getOrderList()
    }
    const openRecord = (id) => {
      state.memberId = id
      state.orderList = []
      state.orderPage = 1
      getOrderList()
    }
    const getOrderList = () => {
      const data = {
        memberId: state.memberId,
        storeId: state.storeId,
        pageNum: state.orderPage,
        bizScope: state.tab,
        status: 'COMPLETED'
      }
      post('/order.list', data).then(res => {
        if (res.code === 0) {
          if (!state.orderShow) state.orderShow = true
          if (data.pageNum === 1) {
            state.orderList = res.data.content
          } else {
            state.orderList = [...state.orderList, ...res.data.content]
          }
          state.orderLoading = false
          state.orderFinished = res.data.last
          state.orderPage++
        } else {
          Toast(res.msg)
        }
      })
    }
    const openTag = () => {
      state.tagText = ''
      state.showTag = true
    }
    const openBox = (type, staffId) => {
      state.memberType = type
      state.memberPage = 1
      state.memberList = []
      state.memberIds = []
      state.staffId = staffId
      getMemberList()
      state.memberShow = true
    }
    const onCreate = () => {
      useRouter.push({ name: 'StaffCreate', query: { type: 1 } })
    }
    const onClear = () => {
      state.searchKey = ''
      reset()
    }
    const onSearch = (value) => {
      reset()
    }
    const onMemberClear = (value) => {
      state.searchMemberKey = ''
      state.memberPage = 1
      state.memberList = []
      state.memberIds = []
      getMemberList()
    }
    const onMemberSearch = (value) => {
      state.memberPage = 1
      state.memberList = []
      state.memberIds = []
      getMemberList()
    }
    const pickMmber = (memberId) => {
      if (state.memberIds.indexOf(memberId) !== -1) {
        const index = state.memberIds.indexOf(memberId)
        state.memberIds.splice(index, 1)
      } else {
        state.memberIds.push(memberId)
      }
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const bindMember = () => {
      if (state.memberIds.length === 0) {
        Toast(`请选择需要${state.memberType === 1 ? '绑定' : '解除'}的用户`)
        return false
      }
      const data = {
        staffId: state.staffId,
        memberIds: state.memberIds,
        type: state.memberType
      }
      post('/member.bind', data).then(res => {
        if (res.code === 0) {
          state.memberPage = 1
          state.memberList = []
          state.memberIds = []
          getMemberList()
          Toast(`${state.memberType === 1 ? '绑定' : '解除'}成功`)
        } else {
          Toast(res.msg)
        }
      })
    }
    const getMemberList = () => {
      state.memberLoading = true
      const data = {
        pageNum: state.memberPage,
        storeId: state.storeId,
        staffId: 0,
        phone: state.searchMemberKey
      }
      if (state.memberType === 2) {
        data.staffId = state.staffId
      }
      post('/member.list', data).then(res => {
        state.memberLoading = false
        if (data.pageNum === 1) {
          state.memberList = res.data.content
        } else {
          state.memberList = [...state.memberList, ...res.data.content]
        }
        state.memberFinished = res.data.last
        state.memberPage++
      })
    }
    const getData = () => {
      post('/staff.list', {
        pageNum: state.page,
        storeId: state.storeId,
        phone: state.searchKey,
        type: 1
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const getMemberDetail = (memberId, index) => {
      state.listIndex = index
      post('/member.get', {
        memberId: memberId
      }).then(res => {
        console.log(res)
        if (res.code === 0) {
          state.memberForm = {
            memberId: memberId,
            name: res.data.name,
            gender: res.data.gender,
            birthday: res.data.birthday,
            remarks: res.data.remarks,
            address: res.data.address,
            region: res.data.region,
            label: res.data.label ? res.data.label.split(',') : []
          }
          state.memberPhone = res.data.user.phone
          state.detailShow = true
        } else {
          Toast(res.msg)
        }
      })
    }
    const onConfirm = (values) => {
      state.memberForm.region = values.map((item) => item.name).join('/')
      state.showArea = false
    }
    const onBirthConfirm = (values) => {
      const y = new Date(values).getFullYear()
      const m = new Date(values).getMonth() + 1
      const d = new Date(values).getDate()
      state.memberForm.birthday = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`
      state.showBirth = false
    }
    const tagConfirm = () => {
      if (state.tagText) {
        state.memberForm.label.push(state.tagText)
      }
    }
    const delTag = (index) => {
      state.memberForm.label.splice(index, 1)
    }
    const onSubmit = () => {
      if (!state.memberForm.name) return Toast('请输入会员姓名')
      if (!state.memberForm.birthday) return Toast('请选择会员生日')
      if (!state.memberForm.region) return Toast('请选择会员所在区域')
      if (!state.memberForm.address) return Toast('请输入会员详细地址')
      const data = JSON.parse(JSON.stringify(state.memberForm))
      if (data.label.length > 0) {
        data.label = data.label.join(',')
      } else {
        data.label = ''
      }
      state.btnLoading = true
      post('/member.update', data).then(res => {
        state.btnLoading = false
        if (res.code === 0) {
          state.detailShow = false
          state.memberList[state.listIndex].name = data.name
          Toast('保存成功')
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onSearch,
      onClear,
      onCreate,
      openBox,
      getMemberList,
      pickMmber,
      bindMember,
      getMemberDetail,
      onConfirm,
      onBirthConfirm,
      openTag,
      tagConfirm,
      delTag,
      onSubmit,
      changeTab,
      openRecord,
      getOrderList,
      onMemberClear,
      onMemberSearch
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
.listbox{
  overflow-y: scroll;
}
.boderbox{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #666666;
  box-sizing: border-box;
}

.formbox{
  width: 90%;
  height: 90vh;
  margin:5vh auto;
  background: #FFFFFF;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 0;
  border-radius: 6px;
}
.infobox{
  border-bottom: 1px solid #f3f3f3;
  padding: 12px 0;
}
.infobox p{
  margin-bottom: 6px;
}
.infobox p:last-child{
  margin-bottom: 0;
}
</style>
